import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap';
import videoBg from '../assets/beach.mp4';

const Home = () => {
  return(
    <div>
      <video style={{width: "100%", height: "105vh", objectFit: "cover"}}
        src={videoBg} controls muted autoPlay={"autoPlay"} preload="auto" loop>
        Your browser is not supported
      </video>
      <div style={{position: "absolute", top: "25%", left: "25%"}}>
        <h1>Hello, I'm Cindy</h1> 
        <h3>Web Developer</h3>
        <Button variant="outline-light mt-3">
          <Link to="/project" style={{fontWeight: "700", color: "black", textDecorationLine: "none"}}>View my work</Link>
          </Button>
      </div>
    </div>
  )
}

export default Home