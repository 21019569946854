import { Row, Col, Container } from 'react-bootstrap'
import ProjectCard from '../components/ProjectCard'
import {projectData} from '../data/projectData'

const Project = () => {
  return(
    <Container>
      <h1 className='text-center mt-3 mb-5'>All Projects</h1>
      <Row xs={1} sm={1} md={2} lg={3} xl={3} className='g-5'>
        {
          projectData.map((project => (
            <Col key={project.id}>
              <ProjectCard 
                title={project.title} 
                skill={project.skills} 
                img={project.img} 
                link={project.link}
              />
            </Col>
          )))
        }
      </Row>
    </Container>
  )
}

export default Project