import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink, Link } from 'react-router-dom';

function Navmenu() {
  return (
    <Navbar collapseOnSelect expand="md" sticky="top" style={{backgroundColor: "lightBlue"}} className='bg-light'>
      <Container>
        <Navbar.Brand href="#home" style={{fontFamily: "Niconne", fontWeight: "700", fontSize: "50px"}}>Cindy</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto m-3">
            <Nav.Link to='/' as={NavLink}>Home</Nav.Link>
            <Nav.Link to='/about' as={NavLink}>About</Nav.Link>
            <Nav.Link to='/contact' as={NavLink}>Contact</Nav.Link>
            <NavDropdown title="Project" id="basic-nav-dropdown">
              <NavDropdown.Item to='/project/ecommerce' as={Link}>Ecommerce</NavDropdown.Item>
              <NavDropdown.Item to='/project/fetch-api' as={Link}>Fetch API</NavDropdown.Item>
              <NavDropdown.Item to='/project' as={Link}>All Projects</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item to='/project/design-ba' as={Link}>
                Design & Business Analysis
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navmenu;