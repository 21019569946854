
import { Routes, Route } from 'react-router-dom'
import './App.css';
import Navmenu from './components/Navbar';
import About from './pages/About';
import Home from './pages/Home'
import Contact from './pages/Contact'
import Project from './pages/Project';
import ProjectEcom from './pages/ProjectEcom';
import ProjectFetch from './pages/projectFetch';
import DesignBA from './pages/DesignBA';

function App() {
  return (
    <div className="">
      <Navmenu />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/project' element={<Project />} />
        <Route path='/project/ecommerce' element={<ProjectEcom />} />
        <Route path='/project/fetch-api' element={<ProjectFetch />} />
        <Route path='/project/design-ba' element={<DesignBA />} />
      </Routes>
    </div>
  );
}

export default App;
