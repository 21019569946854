//import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


function ProjectCard({title, skill, img, link}) {
  return (
    <Card >  
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>
          {skill}
        </Card.Text> 
        <Card.Link href={`${link}`} target={"_blank"} >Project Link</Card.Link> 
      </Card.Body>
      <Card.Img  variant="" src={`${img}`} />
    </Card>
  );
}

export default ProjectCard