import { Container, Row, Card } from "react-bootstrap"
import wireframe from '../assets/wireframe.jpg'
import bpmn1 from '../assets/bpmn-as-is.jpg'
import bpmn2 from '../assets/bpmn-to-be.jpg'
import topology from '../assets/topology.jpg'

const DesignBA = () => {
  return(
    <Container>
    <h1 className='text-center mt-3 mb-5'>Coffee2go Design & Business Analysis</h1>
    <Row className="g-5">
      <Card >  
        <Card.Body>
          <Card.Title>Coffee2go Scenario</Card.Title>
          <Card.Text>
          <p>Before lockdown, Coffee2go is a small business that sells freshly roasted top-quality coffee to walk-in office workers in Sydney CBD.</p>
          <p>Due to lockdown, Coffee2go decided to change business by providing their coffee service in key suburbs
            using a <strong>fresh coffee instantly on your doorstep</strong> marketing campaign - Coffee2go App
            <li>Easy to use/order</li>
            <li>Rewards (buy 10 get 1 free)</li>
            <li>Tracking map</li>
          </p>
          </Card.Text> 
        </Card.Body>
        <Card.Img  variant="" src="" />
    </Card>
    <Card >  
      <Card.Img  variant="" src={wireframe} />
    </Card>
    <Card >  
      <Card.Img  variant="" src={bpmn1} />
    </Card>
    <Card >  
      <Card.Img  variant="" src={bpmn2} />
    </Card>
    <Card >  
      <Card.Img  variant="" src={topology} />
    </Card>
    </Row>
  </Container>
  )
}

export default DesignBA