export const projectData = [
  {
    id: 1,
    category: "fullStack",
    title: "Full Stack MERN Social Media App",
    skills: "Mongoose, Express, React Hooks Redux, Nodejs",
    link: "https://memories-mern-app-ekps.onrender.com/",
    img: "/imgs/memories.jpg"
  },
  {
    id: 2,
    category: "ecommerce",
    title: "Shopping cart",
    skills: "TypeScript, React, AWS Amplify, Auth, API Gateway",
    link: "https://main.d14d9ecagii7tc.amplifyapp.com/",
    img: "/imgs/shoppingCart.jpg"
  },
  {
    id: 3,
    category: "game",
    title: "Tic-tac-toe game",
    skills: "TypeScript, React, Next.js",
    link: "https://tic-tac-toe-xi-seven.vercel.app/",
    img: "/imgs/tictactoe.jpg"
  },
  {
    id: 4,
    category: "frontEnd",
    title: "Music albums",
    skills: "HTML5, CSS3, JavaScript",
    link: "https://my-music-albums.netlify.app/",
    img: "/imgs/retro.JPG"
  },
  {
    id: 5,
    category: "api",
    title: "Fetch recipe data from API",
    skills: "JavaScript, Axios",
    link: "https://my-recipe-api.netlify.app/",
    img: "/imgs/fetchrecipe.jpg"
  },
  // {
  //   id: 6,
  //   category: "ecommerce",
  //   title: "WordPress Woocommerce",
  //   skills: "Tidio Chatbot, Appointment Hour Booking, Stripe",
  //   link: "https://gyguzheng.com.au/",
  //   img: "/imgs/gyguzheng.jpg"
  // },
  {
    id: 7,
    category: "github",
    title: "Github & Bitbucket",
    skills: "Skills & Projects",
    link: "https://github.com/happycindy26",
    img: "/imgs/github.jpg"
  },
]