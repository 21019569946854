import { Row, Col, Container } from 'react-bootstrap'
import ProjectCard from '../components/ProjectCard'
import {projectData} from '../data/projectData'

const ProjectEcom = () => {
  const ecomData = projectData.filter(project => project.category === "ecommerce")
  return(
    <Container>
      <h1 className='text-center mt-3 mb-5'>Ecommerce</h1>
      <Row xs={1} sm={1} md={2} lg={3} xl={3} className='g-5'>
        {
          ecomData.map((eproject => (
            <Col key={eproject.id}>
              <ProjectCard 
                title={eproject.title} 
                skill={eproject.skills} 
                img={eproject.img} 
                link={eproject.link}
              />
            </Col>
          )))
        }
      </Row>
    </Container>
  )
}

export default ProjectEcom