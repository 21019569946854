import { useState } from "react"
import { Form, Button, Container } from "react-bootstrap"
import { Amplify, API } from "aws-amplify"
import awsmobile from "../aws-exports"

Amplify.configure({...awsmobile, ssr: true});

const Contact = () => {
  const [inputs, setInputs] = useState({name:'', email: '', message: ''})
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setInputs({...inputs, [event.target.name]: event.target.value})
  }
  const handleSubmit = async(event) => {
    event.preventDefault();
    setLoading(true);
    alert(`Thank you ${inputs.name}, your message has been successfuly sent! I will get back to you asap. `);
    setInputs({name:'', email: '', message: ''})
    const response = await postData();
    console.log(response)
  }
  async function postData() {
    const apiName = 'portfolioContactForm';
    const path = '/contact';
    const myInit = {
      body: inputs, // replace this with attributes you need
      headers: {} // OPTIONAL
    };
  
    return await API.post(apiName, path, myInit);
  }
  
  return(
    <div style={{width: "100%", height: "150vh", backgroundImage: "url(imgs/grass3.jpg"}}>
    <h1 className="text-center pt-3">Contact</h1>
    <Container className="p-3 my-5 d-flex flex-column w-80 bg-light">
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Your name</Form.Label>
        <Form.Control name="name" type="name" placeholder="Enter name" value={inputs.name} onChange={handleChange} disabled={loading} required/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Your email</Form.Label>
        <Form.Control name="email" type="email" placeholder="name@example.com" value={inputs.email} onChange={handleChange} disabled={loading} required/>
      </Form.Group>
      {/* <Form.Group className="mb-3" controlId="formBasicNumber">
        <Form.Label>Your contact number (Optional)</Form.Label>
        <Form.Control name="number" type="number" placeholder="Enter phone number" value={inputs.number} onChange={handleChange} />
      </Form.Group> */}
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Message</Form.Label>
        <Form.Control name="message" as="textarea" rows={3} value={inputs.message} onChange={handleChange} disabled={loading} required />
      </Form.Group>
      <Button variant="primary" type="submit" disabled={loading}>
        Submit
      </Button>
    </Form>
    </Container>
    </div>
  )
}

export default Contact