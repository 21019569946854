import { useState } from "react"
import { Button } from "react-bootstrap"
import { projectData } from "../data/projectData"

const About = () => {
  const [viewMore, setViewMore] = useState(true)
  const slicedProjectData = viewMore? projectData.slice(0, 3) : projectData
  const handleClick = () => {
    setViewMore(!viewMore)
  }

  return(
    <div style={{height: "150vh", 
      backgroundImage: "linear-gradient(to left bottom,white, #E0F7FA,#FBE9E7,#80DEEA,#00BCD4, #00838F) "}} >
      <div className="mx-3 mb-3 text-center">
        <h1 className="py-3">About</h1>
        <h5>I'm passionate about coding and new technologies...</h5>
        <p>Besides of coding, I like exploring nature, walking, hiking, running, yoga, playing musical instrument...</p>
      </div>
      <div className="d-flex " style={{flexWrap: "wrap", justifyContent: "space-around", alignContent: "center"}}>
        <div className="mx-3 my-3">
          <h5>Key skills</h5>
          <ul>
            <li>HTML5, CSS3, JavaScript, TypeScript, Python3, PHP, MySQL</li>
            <li>React, Hooks, Redux, Bootstrap, Styled-components</li>
            <li>REST API, Axios, AWS, Firebase Firestore, Salesforce</li>
            <li>Git, Github, Bitbucket, VSC</li>
          </ul>
        </div>
        <div className="mx-3 my-3">
          <h5>Project links</h5>
          {<ul>
            {slicedProjectData.map(project =>(
              <li className='my-2' key={project.id}><a href={`${project.link}`} target="_blank" rel="noopener noreferrer">{project.title}</a>: {project.skills}</li>
            ))}
          </ul>}  
          {viewMore && <Button variant="outline-dark" size="sm" onClick={handleClick}>View more</Button>}
          {/* <Link href={`${project.link}`} target={"_blank"} >{project.title}</Link>  */}   
        </div>
      </div>
    </div>
  )
}

export default About